<template>
  <div class="page-main-box">
    <!-- 左边栏 -->
    <div class="left-bar">
      <a-button
        class="add-btn"
        type="primary"
        @click="addModal = true"
        v-if="$store.getters.roles.includes('AuthorityButton')"
        >新增角色</a-button
      >
      <p class="mt-32 mb-16 ml-16 fz-16 fw-600">角色列表</p>
      <ul class="city-list">
        <li
          v-for="item in tabList"
          :key="item.id"
          :class="{ actived: item.id === tabKey }"
          @click="handleTabChange(item.id)"
        >
          <span>{{
            item.name?.length > 8
              ? item.name.toString().substr(0, 8) + "..."
              : item.name
          }}</span>
          <Icon
            v-if="
              item.id === tabKey &&
                item.visible &&
                $store.getters.roles.includes('AuthorityButton')
            "
            class="delete mr-16"
            width="16"
            height="16"
            name="iconshanchu"
            @click="handleDeleteRole(item.id)"
          ></Icon>
        </li>
      </ul>
    </div>

    <!-- 右边栏 -->
    <div class="right-content">
      <div class="title d-flex justify-space-between align--center">
        <p class="fz-16">{{ detailInfo.name }}</p>
        <div class="fz-12 d-flex" style="color:#808080;">
          <p class="mr-24">创建人：{{ detailInfo.creator }}</p>
          <p>
            创建时间：
            {{
              detailInfo.create_time &&
                formatToDateTime(detailInfo.create_time, "yyyy-MM-dd hh:mm:ss")
            }}
          </p>
        </div>
      </div>

      <Collapse
        class="mt-24"
        v-model:activeKey="activeKey[item.code]"
        expandIconPosition="right"
        v-for="item in menuTree"
        :key="item.code"
      >
        <CollapsePanel key="1">
          <template #header>
            <Checkbox
              v-model:checked="item.check"
              @change="handleCheckChange(item)"
              @click="handleCheckClick"
              :disabled="!isSuper"
            >
              <span @click="handleCheckClick">{{ item.label }}</span>
            </Checkbox>
          </template>
          <ul>
            <li
              class="second-list"
              v-for="second in item.children"
              :key="second.id"
            >
              <div class="ml-80" style="width:160px;">
                <Checkbox
                  v-model:checked="second.check"
                  @change="handleCheckChange(second)"
                  @click="handleCheckClick"
                  :disabled="!isSuper"
                >
                  <span @click="handleCheckClick">{{ second.label }}</span>
                </Checkbox>
              </div>

              <div>
                <Checkbox
                  class="mr-16"
                  v-for="third in second.children"
                  :key="third.id"
                  v-model:checked="third.check"
                  @change="handleCheckChange(third)"
                  @click="handleCheckClick"
                  :disabled="isSuper ? false : true"
                >
                  <span @click="handleCheckClick">{{ third.label }}</span>
                </Checkbox>
              </div>
            </li>
          </ul>
        </CollapsePanel>
      </Collapse>

      <div class="bottom">
        <a-button
          type="primary"
          @click="handleSubmit"
          :loading="isLoading"
          :disabled="!$store.getters.roles.includes('AuthorityButton')"
          >保存</a-button
        >
      </div>
    </div>

    <!-- 新增角色弹窗 -->
    <AddAuthority
      v-if="addModal"
      :visible="addModal"
      @getData="getRoleList"
      @cancel="addModal = false"
    ></AddAuthority>

    <!-- 删除确认框 -->
    <xModal
      :w="288"
      :visible="deleteModal"
      :closable="false"
      @handleOk="handleOk"
      @handleCancel="deleteModal = false"
    >
      <template #content>
        <p class="text-center">确定要删除该角色吗？</p>
      </template>
    </xModal>
  </div>
</template>

<script>
import $await from "@/utils/await";
import { ref, watch, computed } from "vue";
import { Collapse, Checkbox, message } from "ant-design-vue";
import xModal from "@/components/xModal";
import {
  useRoleListApi,
  useRoleDetailApi,
  useMenuTreeApi,
  useUpdateMenuApi,
  useRoleDeleteApi
} from "@/apis/system";
import AddAuthority from "./components/AddAuthority/index";
import { getCheckBoxStatus } from "./hooks/useGetCheckBoxStatus";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import { useStore } from "vuex";

export default {
  name: "Authority",
  components: {
    AddAuthority,
    Collapse,
    CollapsePanel: Collapse.Panel,
    Checkbox,
    xModal
  },
  setup() {
    const store = useStore();
    const roleListApi = useRoleListApi();
    const roleDetailApi = useRoleDetailApi();
    const menuTreeApi = useMenuTreeApi();
    const updateMenuApi = useUpdateMenuApi();
    const roleDeleteApi = useRoleDeleteApi();

    const isLoading = ref(false);
    const tabList = ref([]);
    const tabKey = ref("");
    const addModal = ref(false);
    const deleteModal = ref(false);
    // 判断是否是超级管理员
    const isSuper = computed(() => {
      const has = store.state.user.roles.includes("SUPER");
      return has;
    });
    // 树数据
    const menuTree = ref([]);
    // 控制折叠面板展开
    const activeKey = ref({});
    // 角色详情
    const detailInfo = ref({});
    // 角色id
    const roleId = ref("");
    // 菜单选中id集合
    const menuIds = ref([]);
    // 最后一个更改状态的复选框
    const lastItem = ref({});

    // 根据左侧菜单切换动态获取menuIds
    watch(tabKey, async val => {
      let [, res] = await $await(roleDetailApi({ ids: [val] }));
      menuIds.value = res.menu_ids;
      roleId.value = res.id;
      detailInfo.value = res;

      const { keys, tree } = getCheckBoxStatus(menuTree.value, menuIds.value);
      activeKey.value = keys;
      menuTree.value = tree;
    });

    // 获取角色列表
    const getRoleList = async () => {
      // visible为true代表排除默认角色（默认角色不可修改和删除）
      let [, res] = await $await(roleListApi());
      if (res) {
        tabList.value = res;
        tabKey.value = tabList.value[0]?.id;
      }
    };
    getRoleList();

    // 获取菜单树
    const getMenuTree = async () => {
      // visible为true代表排除默认角色（默认角色不可修改和删除）
      let [, res] = await $await(menuTreeApi());
      if (res) {
        // 筛选调通用权限不展示
        res.splice(0, 1);
        menuTree.value = res;
      }
    };
    getMenuTree();

    // 切换tab
    const handleTabChange = async key => {
      tabKey.value = key;
    };

    // 切换复选框状态
    const handleCheckChange = item => {
      if (item.check) {
        menuIds.value.push(item.id);
      } else {
        menuIds.value.splice(menuIds.value.indexOf(item.id), 1);
      }

      lastItem.value = item;
    };

    watch(
      menuTree,
      val => {
        // 复选框是选中状态
        if (lastItem.value.check) {
          const filterItem = (id, arr) => {
            arr.forEach(item => {
              if (item.id === id) {
                item.check = true;
                menuIds.value.push(item.id);
                lastItem.value = item;
              } else {
                if (item.children) {
                  filterItem(lastItem.value.parentId, item.children);
                }
              }
            });
          };
          filterItem(lastItem.value.parentId, val);
        } else {
          // 复选框为取消状态
          const filterItem = (id, arr) => {
            arr.forEach(item => {
              if (item.id === lastItem.value.id) {
                if (item.children) {
                  const cancelCheck = children => {
                    children.forEach(child => {
                      child.check = false;
                      // 如果menuIds中有这个id才会移除，否则会返回(-1，1)删除全部
                      if (~menuIds.value.indexOf(child.id)) {
                        menuIds.value.splice(
                          menuIds.value.indexOf(child.id),
                          1
                        );
                      }
                      if (child.children) cancelCheck(child.children);
                    });
                  };
                  cancelCheck(item.children);
                }
              } else {
                if (item.children) {
                  filterItem(lastItem.value.id, item.children);
                }
              }
            });
          };
          filterItem(lastItem.value.id, val);
        }
        // 最后去重操作
        menuIds.value = Array.from(new Set(menuIds.value));
      },
      { deep: true }
    );

    // 提交
    const handleSubmit = async () => {
      isLoading.value = true;
      const [, res] = await $await(
        updateMenuApi({ menu_ids: menuIds.value, role_id: roleId.value })
      );
      if (res) {
        message.success("保存成功！");
      }
      isLoading.value = false;
    };

    // 删除角色
    const handleDeleteRole = id => {
      roleId.value = id;
      deleteModal.value = true;
    };
    // 确认删除角色
    const handleOk = async () => {
      const [, res] = await $await(roleDeleteApi({ ids: [roleId.value] }));
      if (res) {
        message.success("删除成功！");
        getRoleList();
        deleteModal.value = false;
      }
    };

    const handleCheckClick = event => {
      event.stopPropagation();
    };

    return {
      isLoading,
      menuTree,
      addModal,
      tabKey,
      detailInfo,
      activeKey,
      deleteModal,
      handleTabChange,
      tabList,
      getRoleList,
      menuIds,
      handleCheckClick,
      handleCheckChange,
      handleSubmit,
      handleDeleteRole,
      handleOk,
      formatToDateTime,
      isSuper
    };
  }
};
</script>

<style lang="less" scoped>
.page-main-box {
  position: relative;
  overflow: hidden;
  display: flex;
  .left-bar {
    width: 214px;
    background: #f8f8f8;
    height: 100%;
    position: fixed;
    .add-btn {
      width: 166px;
      height: 32px;
      margin: 24px auto 0;
      display: block;
    }
    .city-list {
      li {
        position: relative;
        height: 48px;
        line-height: 48px;
        padding-left: 24px;
        cursor: pointer;
      }
      .actived {
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .delete {
          background: #f1f1f1;
          padding: 2px;
          box-sizing: content-box;
          border-radius: 2px;
        }
        &::after {
          content: "";
          width: 4px;
          height: 20px;
          position: absolute;
          left: 0;
          top: 12px;
          background: #007aff;
        }
      }
    }
  }
  .right-content {
    margin-left: 214px;
    padding: 32px 24px 88px;
    width: calc(~"100% - 214px");
    overflow: auto;
    height: calc(100vh - 64px);
    position: relative;
    .second-list {
      height: 42px;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      &:nth-last-of-type(1) {
        border: none;
      }
    }
    .bottom {
      height: 56px;
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(~"100% - 414px");
      bottom: 0;
      left: 414px;
      background: #fff;
      border-top: 1px solid #e7e7e7;
    }
  }
}
</style>
<style lang="less">
.page-main-box {
  .ant-collapse-header {
    padding: 10px 16px !important;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
}
</style>
